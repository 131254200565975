/* Section */
.section {
  align-items: stretch;
}

/* Rating large number */
.ratingNumber {
  font-family: 'GraphikWeb', 'Verdana', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  margin-right: 8px;
}

/* Fixing Input style */
.inputWrapper > div {
  padding: 20px 22px 16px 5px;
  background: none;
}

.inputWrapper label {
  background: var(--tgui--bg_color);
}

/* Prevent Rating input directly on stars */
.ratingStars {
  pointer-events: none;
  /* prevent selection */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* ratingStars */
.ratingStars > label {
  gap: 2px;
  padding: 2px 0px 2px 0px;
}

/* a very hacky way to apply color only to the main svg (ignoring shadow) */
.ratingStars svg[style] {
  color: #891826;
}

/* minor improvement for the dark mode */
.ratingStars[data-theme='dark'] svg[style] {
  color: #ac2a2f;
}

/* Modal content styling */
.modalContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Image styling */
.image {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 20px auto 0;
}

/* Checkbox label styling */
.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Styles for each list item */
.listItem {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 8px;
  transition: background-color 0.2s;
}

/* Hover effect for list items */
.listItem:hover {
  background-color: #f0f0f0;
}

/* Styles for the display container */
.displayContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

/* Styles for the editing container */
.editingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Styles for the name and rating container */
.nameRating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Left-align the text */
.nameRating strong,
.nameRating span {
  text-align: left;
}

/* Styling for the name */
.nameRating strong {
  font-size: 16px;
}

/* Styling for the rating */
.nameRating span {
  font-size: 14px;
  color: gray;
}

/* Delete button styling */
.deleteButton {
  background: none;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
}

.deleteButton:hover {
  color: #ff7875;
}

/* Edit buttons styling */
.editButtons {
  background: none;
  border: none;
  display: flex;
  gap: 5px;
}

/* Alternating background colors */
.evenItem {
  background-color: #ffffff;
}

.oddItem {
  background-color: #fafafa;
}
